body.dark {
  background-color: #000;
  color: #555;
}

body.dark tr {
  border-color: #333;
}

body.dark .table {
  color: #555;
}